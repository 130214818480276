export enum QueryOperator {
    Is = 0,
    IsNot = 1,
    IsKnown = 2,
    IsNotKnown = 3,
    LessThan = 4,
    LessThanOrEqual = 5,
    GreaterThan = 6,
    GreaterThanOrEqual = 7,
    Contains = 8,
    StartsWith = 9,
    EndsWith = 10,
    IsTrue = 11,
    IsFalse = 12,
    IsSet = 13,
    IsNotSet = 14,
    In = 15,
    NotIn = 16,
    Between = 17,
    Before = 18,
    After = 19,
    IsAnyOf = 20,
    IsNoneOf = 21,
    Matches = 22,
    OnOrBefore = 28,
    OnOrAfter = 29,
}