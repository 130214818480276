import { ICustomComponent, IPage } from "@/builder/interfaces";
import { getEmptyComponent } from "@/builder/utilities";
import { ComponentType } from "../../enums";

export interface IButtonGroupComponent extends ICustomComponent {
    controls: {
        canMove: true;
        canEdit: true;
        canDelete: true | false;
        allowChildren: true;
        allowMultiple: true;
    };
    customData: {
    };
    errors: [];
    label: string;
}

export const getButtonGroupComponent = (page: IPage) => getEmptyComponent(ComponentType.ButtonGroup, page);
